import { FILTER_DATE_RANGE, FILTER_TEAMS, TeamFilterActionValues } from '@context/filtersTasksContext';
import { RoomsFilterType, Team } from '@typings/enums';
import { DATE_FORMAT_SHORT, formatDate } from '@utils/dateUtils';
import { TFunction } from 'react-i18next';

export const updateDefaultFiltersBasedOnUserRole = (
  userTeam: Team,
  updateFilterParams: (action: TeamFilterActionValues) => void,
  t: TFunction<'translation', undefined>,
  id?: typeof FILTER_DATE_RANGE | typeof FILTER_TEAMS,
) => {
  const today = new Date();
  const nextWeek = new Date();
  nextWeek.setDate(today.getDate() + 6);
  const dueStartDateLabel = formatDate(today, DATE_FORMAT_SHORT);
  const dueEndDateLabel = formatDate(nextWeek, DATE_FORMAT_SHORT);

  if (userTeam === Team.OPERATIONS) {
    if (!id || id === FILTER_DATE_RANGE) {
      // 7 days date range
      updateFilterParams({
        id: FILTER_DATE_RANGE,
        label: `${dueStartDateLabel} - ${dueEndDateLabel}`,
        value: [today, nextWeek],
      });
    }
    if (!id || id === FILTER_TEAMS) {
      // Operations see all teams by default
      updateFilterParams({
        id: FILTER_TEAMS,
        label: [t(Team.HOUSE_KEEPING), t(Team.MAINTENANCE), t(Team.OPERATIONS)],
        value: [Team.HOUSE_KEEPING, Team.MAINTENANCE, Team.OPERATIONS],
      });
    }
  } else if (userTeam === Team.MAINTENANCE) {
    if (!id || id === FILTER_DATE_RANGE) {
      // 7 days date range
      updateFilterParams({
        id: FILTER_DATE_RANGE,
        label: `${dueStartDateLabel} - ${dueEndDateLabel}`,
        value: [today, nextWeek],
      });
    }
    if (!id || id === FILTER_TEAMS) {
      updateFilterParams({ id: FILTER_TEAMS, label: [t(userTeam)], value: [userTeam] });
    }
  } else if (userTeam === Team.HOUSE_KEEPING) {
    if (!id || id === FILTER_DATE_RANGE) {
      // 1 day date range
      updateFilterParams({
        id: FILTER_DATE_RANGE,
        label: `${dueStartDateLabel} - ${dueStartDateLabel}`,
        value: [today, today],
      });
    }
    if (!id || id === FILTER_TEAMS) {
      updateFilterParams({ id: FILTER_TEAMS, label: [t(userTeam)], value: [userTeam] });
    }
  } else {
    // 1 day date range
    updateFilterParams({
      id: FILTER_DATE_RANGE,
      label: `${dueStartDateLabel} - ${dueStartDateLabel}`,
      value: [today, today],
    });
  }
};

type getUnitsAndAreasProps =
  | {
      id: string;
      type: RoomsFilterType;
    }[]
  | null;

export const getUnitsAndAreas = (unitsAndArea: getUnitsAndAreasProps) => {
  const result: { units: string[] | null; areas: string[] | null } = {
    units: null,
    areas: null,
  };

  if (!unitsAndArea) return null;

  for (const value of unitsAndArea) {
    if (value.type === RoomsFilterType.UNIT) {
      result.units = result.units ?? [];
      result.units.push(value.id);
    } else if (value.type === RoomsFilterType.AREA) {
      result.areas = result.areas ?? [];
      result.areas.push(value.id);
    }
  }

  return result;
};
