import { useGetNotifications } from '@api/api';
import LoadingMessage from '@atoms/LoadingMessage';
import Transition from '@atoms/Transition';
import usePropertiesState from '@context/propertiesContext';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import DamageReportDetails from '@organisms/DamageReportDetails';
import NotificationItem from '@organisms/NotificationItem';
import { TaskDetailsDialog } from '@organisms/TaskDetails';
import { TaskType } from '@typings/enums';
import { NotificationDto, NotificationDtoType } from '@typings/types';
import useSearchParam from '@utils/hooks/useSearchParam';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function Notifications() {
  const { t } = useTranslation();

  const { selectedProperty } = usePropertiesState();
  const propertyId = selectedProperty?.id;

  const [openedItem, setOpenedItem] = useState<NotificationDto>();
  const [taskId, setTaskId] = useSearchParam({ key: 'taskId' });
  const [taskType, setTaskType] = useSearchParam<TaskType>({ key: 'taskType', defaultValue: TaskType.STANDARD });

  const { isFetching, error, data: notificationsByProperty } = useGetNotifications();

  useEffect(() => {
    if (isFetching || !taskId) {
      return;
    }
    const allNotifications = Object.values(notificationsByProperty?.map((n) => n.notifications) ?? {}).flat();
    for (const notification of allNotifications) {
      const type = notification.metadata.task.type;
      const isDamageReportNotification = type === TaskType.DAMAGE && notification.metadata.task.id === taskId;
      if (isDamageReportNotification) {
        setOpenedItem(notification);
        setTaskType(type);
        break;
      }
    }
  }, [taskId, isFetching]);

  const onNotificationClick = (item: NotificationDto) => {
    const taskId = item.metadata?.task.id;
    if (!taskId) {
      // not a task notification, skip
      return;
    }
    const type = item.metadata?.task.type;

    setTaskId(taskId);
    setTaskType(type);

    if (type === TaskType.DAMAGE) {
      setOpenedItem(item);
    }
  };

  const onAnyOverlayClose = () => {
    setTaskId(null);
    setOpenedItem(undefined);
    setTaskType(null);
  };

  if (error) {
    return <div>{t('anErrorHasOccurred')}</div>;
  }

  if (!propertyId || !notificationsByProperty) {
    return <LoadingMessage />;
  }

  return (
    <>
      {notificationsByProperty.map((property) => (
        <Disclosure key={property.propertyId} defaultOpen={true}>
          {({ open }) => (
            <div className={'flex flex-col bg-white shadow-lg'}>
              <Disclosure.Button
                className={'flex flex-row flex-1 flex-shrink-0 items-center justify-between px-4 py-3 border-b'}
              >
                <div className={'text-th-primary font-serif font-bold uppercase'}>{property.propertyName}</div>
                <ChevronDownIcon className={`${open ? 'transform rotate-180' : ''} h-5 w-5`} />
              </Disclosure.Button>
              <Transition>
                <Disclosure.Panel>
                  <div className={'flex flex-col'}>
                    {property.notifications.map((item, i) => (
                      <NotificationItem key={i} item={item} onClick={onNotificationClick} />
                    ))}
                  </div>
                </Disclosure.Panel>
              </Transition>
            </div>
          )}
        </Disclosure>
      ))}

      {isFetching && notificationsByProperty.length === 0 && <LoadingMessage />}

      <DamageReportDetails
        task={
          !!openedItem &&
          [NotificationDtoType.DAMAGE_REPORT_DUE, NotificationDtoType.DAMAGE_REPORT_REMINDER].includes(openedItem?.type)
            ? openedItem?.metadata.task
            : undefined
        }
        closeDialog={onAnyOverlayClose}
        // disallow edit / delete
        isCleanerOnly
      />

      {taskType === TaskType.STANDARD && (
        <TaskDetailsDialog propertyId={propertyId} isCleanerOnly onTaskAction={onAnyOverlayClose} />
      )}
    </>
  );
}
