export const CHAT_DEFAULT_ROLE = 'default';

export const CHAT_USER_GROUPS_FOR_TEAMS = [
  {
    id: 'hsk_team',
    name: 'HSK Team',
  },
  {
    id: 'maintenance_team',
    name: 'Maintenance Team',
  },
  {
    id: 'operations_team',
    name: 'Operations Team',
  },
  {
    id: 'gx_team',
    name: 'GX Team',
  },
];
