import Popover from '@molecules/Popover';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import ActionItem from '@molecules/ActionItem';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { TaskInfoType } from '@typings/types';
import useAppContext from '@context/appContext';
import icons from '@constants/icons';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';
import { taskLogsUrl } from '@constants/url';

interface Props {
  task: TaskInfoType;
  onClickEdit: (task: TaskInfoType) => void;
  onClickDelete: (task: TaskInfoType) => void;
}

function TaskActions({ task, onClickEdit, onClickDelete }: Props) {
  const { t } = useTranslation();
  const { isApaleoTab } = useAppContext();
  const { isOperationsRole } = useRoleBasedUI();

  const isOperations = isOperationsRole(task.propertyId);

  const openTaskLog = ({ taskId }: { taskId: string }) => {
    window.open(`${taskLogsUrl}?ID=${taskId}`, '_blank', 'noopener,noreferrer');
  };

  // for resolved do not allow deletion
  return (
    <div className={'flex h-6'} onClick={(e) => e.stopPropagation()}>
      <Popover
        target={<DotsHorizontalIcon className={'w-6 h-6 ml-2 cursor-pointer text-th-secondary'} />}
        contentFn={(close) => (
          <div className={'flex flex-col px-4 md:w-32 py-6 gap-y-4'}>
            {!task?.completedAt && (
              <ActionItem
                name={t('edit')}
                Icon={PencilIcon}
                action={() => {
                  onClickEdit(task);
                  close?.();
                }}
              />
            )}
            {(isApaleoTab || isOperations) && !!task.unit?.id && (
              <ActionItem
                name={t('taskLog')}
                Icon={icons.taskLog}
                action={() => {
                  openTaskLog({ taskId: task.id });
                  close?.();
                }}
              />
            )}
            {!(isApaleoTab && !!task.completedAt) && (
              <ActionItem
                name={t('delete')}
                Icon={TrashIcon}
                action={() => {
                  onClickDelete(task);
                  close?.();
                }}
                isDelete
                isLast
              />
            )}
          </div>
        )}
      />
    </div>
  );
}
export default TaskActions;
