import { ReactComponent as CalendarIcon } from '@assets/icons/navigation/calendar-weekly.svg';
import { ReactComponent as CalendarWithDateIcon } from '@assets/icons/navigation/calendar-daily.svg';
import { ReactComponent as TasksIcon } from '@assets/icons/navigation/tasks.svg';
import { ReactComponent as BellIcon } from '@assets/icons/navigation/bell.svg';
import { ReactComponent as ThreeDotsIcon } from '@assets/icons/navigation/three-dots.svg';
import { ReactComponent as UserFilterIcon } from '@assets/icons/filters/user.svg';
import { ReactComponent as PriorityFilterIcon } from '@assets/icons/filters/priority.svg';
import { ReactComponent as RoomFilterIcon } from '@assets/icons/filters/room.svg';
import { ReactComponent as CalendarFilterIcon } from '@assets/icons/filters/calendar.svg';
import { ReactComponent as RecurrenceFilterIcon } from '@assets/icons/filters/recurrence.svg';
import { ReactComponent as ListFilterIcon } from '@assets/icons/filters/list.svg';
import { ReactComponent as HiddenFilterIcon } from '@assets/icons/filters/hidden.svg';
import { ReactComponent as ReadyToClean } from '@assets/icons/unitstatuses/ready-to-clean.svg';
import { ReactComponent as ReadyToInspect } from '@assets/icons/unitstatuses/ready-to-inspect.svg';
import { ReactComponent as CleaningToday } from '@assets/icons/unitstatuses/cleaning-today.svg';
import { ReactComponent as OccupiedCleaningToday } from '@assets/icons/unitstatuses/occupied-cleaning-today.svg';
import { ReactComponent as Vacant } from '@assets/icons/unitstatuses/vacant.svg';
import { ReactComponent as HskDelayIcon } from '@assets/icons/unitstatuses/hsk-delay.svg';
import { ReactComponent as RoomLogIcon } from '@assets/icons/unitinfo/room-log.svg';
import { ReactComponent as TaskLogIcon } from '@assets/icons/unitinfo/task-log.svg';
import { ReactComponent as Door } from '@assets/icons/unitinfo/door.svg';
import { ReactComponent as User } from '@assets/icons/unitinfo/user.svg';
import { ReactComponent as Adult } from '@assets/icons/unitinfo/adult.svg';
import { ReactComponent as Children } from '@assets/icons/unitinfo/children.svg';
import { ReactComponent as Member } from '@assets/icons/unitinfo/member.svg';
import { ReactComponent as Snacks } from '@assets/icons/unitinfo/snacks.svg';
import { ReactComponent as Info } from '@assets/icons/unitinfo/info.svg';
import { ReactComponent as EmptyCalendarIcon } from '@assets/icons/unitinfo/empty-calendar.svg';
import { ReactComponent as RoomMoveIcon } from '@assets/icons/unitinfo/room-move.svg';
import { ReactComponent as Search } from '@assets/images/search.svg';
import { ReactComponent as InProgress } from '@assets/icons/task-info/in-progress.svg';
import { ReactComponent as TaskCalendar } from '@assets/icons/task-info/calendar.svg';
import { ReactComponent as TaskCalendarChecked } from '@assets/icons/task-info/calendar-checked.svg';
import { ReactComponent as TaskCheckmark } from '@assets/icons/task-info/checkmark.svg';
import { ReactComponent as TapFinger } from '@assets/icons/task-info/tap.svg';
import { ReactComponent as Cleaning } from '@assets/icons/unit-actions/cleaning.svg';
import { ReactComponent as Apaleo } from '@assets/icons/apaleo.svg';
import { SVGIconType } from '@typings/types';

export default {
  calendar: CalendarIcon,
  calendarWithDate: CalendarWithDateIcon,
  userFilter: UserFilterIcon,
  priorityFilter: PriorityFilterIcon,
  roomFilter: RoomFilterIcon,
  calendarFilter: CalendarFilterIcon,
  recurrenceFilter: RecurrenceFilterIcon,
  listFilter: ListFilterIcon,
  hiddenFilter: HiddenFilterIcon,
  tasks: TasksIcon,
  bell: BellIcon,
  threeDots: ThreeDotsIcon,
  readyToClean: ReadyToClean,
  readyToInspect: ReadyToInspect,
  cleaningToday: CleaningToday,
  occupiedCleaningToday: OccupiedCleaningToday,
  vacant: Vacant,
  apaleo: Apaleo,
  roomLog: RoomLogIcon,
  door: Door,
  user: User,
  adult: Adult,
  children: Children,
  member: Member,
  snacks: Snacks,
  info: Info,
  emptyCalendar: EmptyCalendarIcon,
  search: Search,
  roomMove: RoomMoveIcon,
  inProgress: InProgress,
  cleaning: Cleaning,
  hskDelay: HskDelayIcon,
  taskLog: TaskLogIcon,
  taskCalendar: TaskCalendar,
  taskCalendarChecked: TaskCalendarChecked,
  taskCheckmark: TaskCheckmark,
  taskUnconfirmed: TapFinger,
} as {
  [key: string]: SVGIconType;
};
