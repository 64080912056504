export enum UnitStatus {
  OCCUPIED_CLEANING_TODAY = 'OCCUPIED_CLEANING_TODAY',
  READY_TO_CLEAN = 'READY_TO_CLEAN',
  READY_TO_INSPECT = 'READY_TO_INSPECT',
  OCCUPIED = 'OCCUPIED',
  VACANT = 'VACANT',
  STAYOVER_READY_TO_CLEAN = 'STAYOVER_READY_TO_CLEAN',
  STAYOVER_READY_TO_INSPECT = 'STAYOVER_READY_TO_INSPECT',
}

export enum UnitStatusAction {
  CLEAN = 'CLEAN',
  TO_INSPECT = 'TO_INSPECT',
}

export enum AutomatedTaskType {
  KITCHEN_USE = 'KITCHEN_USE',
}

export enum RoomsFilterType {
  UNIT = 'UNIT',
  AREA = 'AREA',
}

export enum ServerStateKey {
  DAILY_VIEW_UNITS = 'daily_view_units',
  PROPERTIES = 'properties',
  UNIT_DETAILS = 'unit_details',
  UNIT_NEXT_CHECKOUT_DATE = 'unit_next_checkout_date',
  PROPERTY_UNITS_AND_AREAS = 'property_units',
  LOST_AND_FOUND_TASKS = 'lost_and_found_tasks',
  DAMAGE_REPORT_TASKS = 'damage_report_tasks',
  TASK = 'task',
  TASKS = 'tasks',
  OVERDUE_TASKS = 'overdue_tasks',
  UNCONFIRMED_TASKS = 'unconfirmed_tasks',
  RESERVATION_TASKS = 'reservation_tasks',
  WEEKLY_VIEW = 'weekly_view',
  UNITS_CLEANED_TODAY_COUNT = 'units_cleaned_today_count',
  CLEANERS = 'cleaners',
  ASSIGNED_ROOMS = 'assigned_rooms',
  DAILY_CLEANING_REPORT = 'daily_cleaning_report',
  ARRIVALS_ON_DAY = 'arrivals_on_day',
  MEMBER_ARRIVALS_ON_DAY = 'member_arrivals_on_day',
  DEPARTURES_ON_DAY = 'departures_on_day',
  MIDSTAYS_ON_DAY = 'midstays_on_day',
  HSK_DELAYS_ON_DAY = 'hsk_delays_on_day',
  NOTIFICATIONS = 'notifications',
  NOTIFICATIONS_COUNT = 'notifications_count',
  NEXT_MIDSTAY_CLEAN = 'next_midstay_clean',
  NOTIFICATION_SETTINGS = 'notification_settings',
  RESERVATION = 'reservation',
  RESERVATION_CURRENT_TIME_SLICE = 'reservation_current_time_slice',
  DAY_SETTINGS = 'day_settings',
  LATE_CHECKOUTS_COUNT = 'late_checkouts_count',
  TASK_CATEGORIES = 'task_categories',
  FEATURE_TOGGLES = 'feature_toggles',
}

export enum Team {
  HOUSE_KEEPING = 'HOUSE_KEEPING',
  OPERATIONS = 'OPERATIONS',
  MAINTENANCE = 'MAINTENANCE',
}

export enum TaskType {
  STANDARD = 'STANDARD',
  MISCONDUCT = 'MISCONDUCT',
  DAMAGE = 'DAMAGE',
  LOST_AND_FOUND = 'LOST_AND_FOUND',
}

export enum StandardizedTask {
  BABY_BED = 'BABY_BED',
  DRYING_RACK = 'DRYING_RACK',
  BABY_CHAIR = 'BABY_CHAIR',
  BLANKET = 'BLANKET',
  BODY_LOTION_REFILL = 'BODY_LOTION_REFILL',
  BRING_BOWL = 'BRING_BOWL',
  BRING_COOKING_POT = 'BRING_COOKING_POT',
  BRING_CUP = 'BRING_CUP',
  BRING_FAN_TO_ROOM = 'BRING_FAN_TO_ROOM',
  BRING_FRYING_PAN = 'BRING_FRYING_PAN',
  BRING_KITCHEN_TOWEL = 'BRING_KITCHEN_TOWEL',
  BRING_PAPER_TOWELS = 'BRING_PAPER_TOWELS',
  BRING_PLATES = 'BRING_PLATES',
  BRING_WATER_GLASS = 'BRING_WATER_GLASS',
  BRING_WINE_GLASS = 'BRING_WINE_GLASS',
  CHECK_CONSUMABLES_EXPIRATION = 'CHECK_CONSUMABLES_EXPIRATION',
  CHECK_LAUNDRY_DELIVERY = 'CHECK_LAUNDRY_DELIVERY',
  CHECK_MAILBOX = 'CHECK_MAILBOX',
  CLEAN_BALCLONY = 'CLEAN_BALCLONY',
  CLEAN_ELEVATOR = 'CLEAN_ELEVATOR',
  CLEAN_ENTRANCE = 'CLEAN_ENTRANCE',
  CLEAN_HALLWAYS = 'CLEAN_HALLWAYS',
  CLEAN_LAUNDRY_ROOM = 'CLEAN_LAUNDRY_ROOM',
  CLEAN_LUGGAGE_LOCKERS = 'CLEAN_LUGGAGE_LOCKERS',
  CLEAN_PUBLIC_TOILETS = 'CLEAN_PUBLIC_TOILETS',
  CLEAN_STAIRS = 'CLEAN_STAIRS',
  CLEAN_TERRACE = 'CLEAN_TERRACE',
  CLEAN_TOILET = 'CLEAN_TOILET',
  CLEAN_WASHING_MACHINE_DRYER = 'CLEAN_WASHING_MACHINE_DRYER',
  COFFEE_PODS = 'COFFEE_PODS',
  DO_NOT_DISTURB_GUEST = 'DO_NOT_DISTURB_GUEST',
  DOUBLE_CHECK_ROOM = 'DOUBLE_CHECK_ROOM',
  IRON_AND_BOARD = 'IRON_AND_BOARD',
  KITCHEN_TOWEL = 'KITCHEN_TOWEL',
  NO_SHOW_AFTER_10 = 'NO_SHOW_AFTER_10',
  ORDER_BEER = 'ORDER_BEER',
  ORDER_COFFEE = 'ORDER_COFFEE',
  ORDER_CRISPS = 'ORDER_CRISPS',
  ORDER_EARPLUGS = 'ORDER_EARPLUGS',
  ORDER_SOFT_DRINK = 'ORDER_SOFT_DRINK',
  ORDER_SWEETS = 'ORDER_SWEETS',
  ORDER_TEA = 'ORDER_TEA',
  ORDER_WATER = 'ORDER_WATER',
  ORGANIZE_STORAGE = 'ORGANIZE_STORAGE',
  PARTY_ROOM_DAMAGE = 'PARTY_ROOM_DAMAGE',
  PRIORITIZE_ROOM_CLEANING = 'PRIORITIZE_ROOM_CLEANING',
  QUARANTINE = 'QUARANTINE',
  REFILL_DETERGENT = 'REFILL_DETERGENT',
  REFILL_ESSENTIAL_CLOSET = 'REFILL_ESSENTIAL_CLOSET',
  REFILL_HSK_HUB = 'REFILL_HSK_HUB',
  RESET_LUGGAGE_LOCKERS = 'RESET_LUGGAGE_LOCKERS',
  SHAMPOO_REFILL = 'SHAMPOO_REFILL',
  SHOWER_GEL_REFILL = 'SHOWER_GEL_REFILL',
  SMOKE = 'SMOKE',
  SOFA_1 = 'SOFA_1',
  SOFA_2 = 'SOFA_2',
  SORRY_TREATMENT = 'SORRY_TREATMENT',
  TAKE_OUT_DIRTY_LAUNDRY = 'TAKE_OUT_DIRTY_LAUNDRY',
  TOILET_PAPER = 'TOILET_PAPER',
  TOWELS = 'TOWELS',
  TRASH = 'TRASH',
  WATER_FLOWERS = 'WATER_FLOWERS',
  WATER_GARDEN = 'WATER_GARDEN',
  // deprecated
  LCO_11_30 = 'LCO_11_30',
  LCO_12 = 'LCO_12',
  CHECK_FOR_MAIL = 'CHECK_FOR_MAIL',
  TURN_HEATING_ON = 'TURN_HEATING_ON',
}

// NO_SHOW_AFTER_10 - do not show it anymore in the task creation list
export const excludedStandardizedTaskOptions: string[] = [
  StandardizedTask.NO_SHOW_AFTER_10,
  StandardizedTask.LCO_11_30,
  StandardizedTask.LCO_12,
  StandardizedTask.CHECK_FOR_MAIL,
  StandardizedTask.TURN_HEATING_ON,
];

// first 4 are not used anymore, but we keep them so the translation can work for old damage reports
export enum StandardizedDamageReport {
  BATHROOM_FAN_LOUD = 'BATHROOM_FAN_LOUD',
  LIGHT_NOT_WORKING = 'LIGHT_NOT_WORKING',
  SINK_BLOCKED = 'SINK_BLOCKED',
  SHOWERDRAIN_BLOCKED = 'SHOWERDRAIN_BLOCKED',
  BATHROOM_FILTER = 'BATHROOM_FILTER',
  SINK = 'SINK',
  TOILET = 'TOILET',
  SHOWER = 'SHOWER',
  STOVE = 'STOVE',
  FRIDGE_MINIBAR = 'FRIDGE_MINIBAR',
  DOOR = 'DOOR',
  WINDOW = 'WINDOW',
  AC = 'AC',
  WIFI = 'WIFI',
  LIGHTBULB = 'LIGHTBULB',
  CHAIRS = 'CHAIRS',
  HEATING = 'HEATING',
  TV = 'TV',
  TABLES = 'TABLES',
  CHROMECAST = 'CHROMECAST',
}

export enum NotificationType {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  ERROR = 'ERROR',
}

export enum DamageReportArea {
  KITCHEN = 'KITCHEN',
  BED = 'BED',
  BATHROOM = 'BATHROOM',
  SEATING_AREA = 'SEATING_AREA',
  WALLS_CEILING_FLOOR = 'WALLS_CEILING_FLOOR',
  ICT = 'ICT',
}

export enum DamageReportActionRequired {
  CLEAN = 'CLEAN',
  REPLACE = 'REPLACE',
  REPAIR = 'REPAIR',
  CHECK = 'CHECK',
  OTHER = 'OTHER',
}

export enum TaskPriority {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export enum DeviceType {
  IOS = 'IOS',
  WEB = 'WEB',
}

export enum RecurrenceFilter {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}
