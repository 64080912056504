import useApiCall from '@utils/hooks/useApiCall';
import { WeeklyViewData, WeeklyViewDateSummary, WeeklyViewPropertyData } from '@typings/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ServerStateKey } from '@typings/enums';
import dateUtils, {
  DATE_FORMAT_SHORT,
  formatDate,
  getDatesBetweenDays,
  REQUEST_DATE_FORMAT,
  toDate,
} from '@utils/dateUtils';
import { useCallback, useMemo, useState } from 'react';
import { addDays, isBefore, startOfDay } from 'date-fns';
import LoadingMessage from '@atoms/LoadingMessage';
import { useTranslation } from 'react-i18next';
import Dropdown from '@molecules/Dropdown';
import propertiesApi from '@api/propertiesApi';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';
import unitsApi from '@api/unitsApi';
import Popover from '@molecules/Popover';
import Spinner from '@atoms/Spinner';
import usePropertiesState from '@context/propertiesContext';
import { useGetWeeklyView } from '@api/api';
import cn from 'classnames';
import { openReservationInApaleo } from '@utils/unitUtils';
import icons from '@constants/icons';
import { isMemberBenefitsActivated } from '@utils/propertyUtils';

const maxApprovedLCOs = 30;

function getApprovedLateCheckoutsOptions(from: number): { value: number | null; label: string }[] {
  if (from > maxApprovedLCOs) return [];
  const options: { value: number | null; label: string }[] = Array(maxApprovedLCOs - from + 1)
    .fill(0)
    .map((_, i) => from + i)
    .map((i) => ({ value: i, label: `${i}` }));
  if (from === 0) {
    options.splice(0, 0, { value: null, label: '-' });
  }
  return options;
}

const latestAllowedCheckoutOptions = [
  { value: '11:30', label: '11:30' },
  { value: '12:00', label: '12:00' },
  { value: '12:30', label: '12:30' },
  { value: '13:00', label: '13:00' },
];

interface Props {
  date: Date;
}

export default function WeeklyView({ date }: Props) {
  const queryClient = useQueryClient();

  const { selectedProperties } = usePropertiesState();
  const propertyIds = selectedProperties.map((p) => p.id);

  const { t } = useTranslation();

  const today = startOfDay(dateUtils.now());

  const { isOperationsRole, isMaintainerRole, isRoomcheckerRole } = useRoleBasedUI();

  const [unitsRequest, setUnitsRequest] = useState<{
    propertyId: string;
    type: 'arrivals' | 'departures' | 'midstays' | 'hskDelays';
    date: Date;
  }>();

  const from = formatDate(date, REQUEST_DATE_FORMAT);
  const { isIdle, error, data } = useGetWeeklyView(propertyIds, from);

  const arrivalsOnDayApiCall = useApiCall<string[]>(unitsApi.arrivalsOnDay);
  const { data: arrivalsOnDay } = useQuery(
    [ServerStateKey.ARRIVALS_ON_DAY, unitsRequest?.propertyId, unitsRequest?.date, unitsRequest?.type],
    () =>
      arrivalsOnDayApiCall({ params: { propertyId: unitsRequest!.propertyId }, query: { date: unitsRequest!.date } }),
    { enabled: unitsRequest?.type === 'arrivals' },
  );

  const memberArrivalsOnDayApiCall = useApiCall<string[]>(unitsApi.arrivalsOnDay);
  const { data: memberArrivalsOnDay } = useQuery(
    [ServerStateKey.MEMBER_ARRIVALS_ON_DAY, unitsRequest?.propertyId, unitsRequest?.date, unitsRequest?.type],
    () =>
      memberArrivalsOnDayApiCall({
        params: { propertyId: unitsRequest!.propertyId },
        query: { date: unitsRequest!.date, membersOnly: true },
      }),
    { enabled: unitsRequest?.type === 'arrivals' },
  );

  const departuresOnDayApiCall = useApiCall<string[]>(unitsApi.departuresOnDay);
  const { data: departuresOnDay } = useQuery(
    [ServerStateKey.DEPARTURES_ON_DAY, unitsRequest?.propertyId, unitsRequest?.date, unitsRequest?.type],
    () =>
      departuresOnDayApiCall({ params: { propertyId: unitsRequest!.propertyId }, query: { date: unitsRequest!.date } }),
    { enabled: unitsRequest?.type === 'departures' },
  );

  const midstaysOnDayApiCall = useApiCall<string[]>(unitsApi.midstaysOnDay);
  const { data: midstaysOnDay } = useQuery(
    [ServerStateKey.MIDSTAYS_ON_DAY, unitsRequest?.propertyId, unitsRequest?.date, unitsRequest?.type],
    () =>
      midstaysOnDayApiCall({ params: { propertyId: unitsRequest!.propertyId }, query: { date: unitsRequest!.date } }),
    { enabled: unitsRequest?.type === 'midstays' },
  );

  const hskDelaysOnDayApiCall = useApiCall<string[]>(unitsApi.hskDelaysOnDay);
  const { data: hskDelaysOnDay } = useQuery(
    [ServerStateKey.HSK_DELAYS_ON_DAY, unitsRequest?.propertyId, unitsRequest?.date, unitsRequest?.type],
    () =>
      hskDelaysOnDayApiCall({ params: { propertyId: unitsRequest!.propertyId }, query: { date: unitsRequest!.date } }),
    { enabled: unitsRequest?.type === 'hskDelays' },
  );

  const saveDaySettingsApiCall = useApiCall<WeeklyViewData>(propertiesApi.saveDaySettings);
  const saveDaySettingsMutation = useMutation(
    (v: { date: Date; approvedLateCheckouts: number; latestAllowedCheckout: string; propertyId: string }) =>
      saveDaySettingsApiCall({
        params: { propertyId: v.propertyId },
        body: {
          date: v.date,
          approvedLateCheckouts: v.approvedLateCheckouts,
          latestAllowedCheckout: v.latestAllowedCheckout,
        },
      }),
    {
      onSuccess: (_, variables) => {
        queryClient.setQueryData([ServerStateKey.WEEKLY_VIEW, propertyIds, from], (oldData?: WeeklyViewData) => {
          const propertyData = oldData?.[variables.propertyId];
          if (!propertyData) return oldData || {};
          const changedItem = oldData[variables.propertyId].data.find((d) => d.date === variables.date);
          if (!changedItem) return oldData || {};
          const indexOfChangedItem = propertyData.data.indexOf(changedItem);
          const newPropertyData: WeeklyViewPropertyData = {
            ...propertyData,
            data: [
              ...propertyData.data.slice(0, indexOfChangedItem),
              {
                ...changedItem,
                approvedLcoLimit: variables.approvedLateCheckouts,
                maxLcoTime: variables.latestAllowedCheckout,
              },
              ...propertyData.data.slice(indexOfChangedItem + 1, propertyData.data.length),
            ],
          };
          return {
            ...oldData,
            [variables.propertyId]: newPropertyData,
          };
        });
      },
    },
  );

  const dates = useMemo(() => {
    const from = startOfDay(date);
    const to = addDays(from, 7);
    return getDatesBetweenDays(from, to);
  }, [date]);

  const renderTableHeader = useCallback(
    (propName: string) => {
      return (
        <div className={'flex flex-row items-center bg-gray-100'}>
          {dates.map((d, i) => (
            <div
              key={`${propName}-${i}`}
              className={'py-2 flex flex-1 justify-center text-gray-400 text-xs md:text-sm'}
            >
              {formatDate(d, DATE_FORMAT_SHORT)}
            </div>
          ))}
        </div>
      );
    },
    [dates],
  );

  const renderTableCell = useCallback((text: number | string, rightBorderClass: string) => {
    return (
      <div className={'py-2 w-full border-b border-gray-100'}>
        <div
          className={`py-1 w-full text-center text-th-secondary font-semibold text-sm md:text-base ${rightBorderClass}`}
        >
          {text}
        </div>
      </div>
    );
  }, []);

  const renderPopoverRooms = useCallback((rooms?: string[] | JSX.Element[]) => {
    if (!rooms) {
      return <Spinner className={'w-6 h-6 text-th-primary'} />;
    }
    if (rooms.length === 0) {
      return <div>{t('noRoomAssigned')}</div>;
    }
    return rooms.map((u, i) => (
      <div key={i} className={'flex mb-1 mr-2'}>
        {u}
      </div>
    ));
  }, []);

  const renderPopoverContent = useCallback((rooms?: string[] | JSX.Element[]) => {
    return (
      <div className={'p-4 flex flex-col flex-wrap max-h-48 md:w-44 md:overflow-scroll'}>
        {renderPopoverRooms(rooms)}
      </div>
    );
  }, []);

  const renderMaxLcoTime = useCallback((dateData: WeeklyViewDateSummary, propertyId: string, allowEdit?: boolean) => {
    const approvedLcoLimit = dateData.approvedLcoLimit;
    if (!approvedLcoLimit) {
      return <span className={'font-bold px-1 rounded bg-gray-200 cursor-not-allowed w-10'}>{'-'}</span>;
    }
    if (!allowEdit) {
      return <span>{approvedLcoLimit > 0 ? dateData.maxLcoTime : '-'}</span>;
    }

    const isDefault = dateData.maxLcoTime === dateData.defaultMaxLcoTime;
    return (
      <Dropdown
        value={dateData.maxLcoTime}
        items={latestAllowedCheckoutOptions}
        onChange={(value) => {
          saveDaySettingsMutation.mutate({
            date: dateData.date,
            approvedLateCheckouts: approvedLcoLimit,
            latestAllowedCheckout: value as string,
            propertyId,
          });
        }}
        className={cn('font-bold px-1 rounded', isDefault ? 'bg-gray-200' : 'bg-yellow-300')}
        relativity={false}
      />
    );
  }, []);

  const renderApprovedLco = useCallback(
    (dateData: WeeklyViewDateSummary, propertyId: string, allowDaySettingsEdit: boolean) => {
      const approvedLcoLimit = dateData.approvedLcoLimit || 0;
      const approvedLcoCount = dateData.approvedLco.length;
      const isPastDate = dateUtils.isBefore(dateUtils.toDate(dateData.date), today);
      const isMismatch = !isPastDate && approvedLcoCount > approvedLcoLimit;
      const isDefault = dateData.approvedLcoLimit === dateData.defaultApprovedLcoLimit;
      const minApprovedLcoValue = Math.min(approvedLcoCount, approvedLcoLimit);
      const approvedLcoOptions = getApprovedLateCheckoutsOptions(minApprovedLcoValue);
      return (
        <>
          <Popover
            target={<span className={cn(isMismatch && 'px-1 text-white bg-th-accent')}>{approvedLcoCount}</span>}
            content={renderPopoverContent(
              dateData.approvedLco.map((lco) => (
                <span
                  className="text-blue-500 underline"
                  key={lco.unitNumber}
                  onClick={() => openReservationInApaleo(propertyId, lco.reservationExternalId)}
                >
                  {lco.unitNumber}
                </span>
              )),
            )}
          />
          <span>/</span>
          {allowDaySettingsEdit ? (
            <Dropdown
              // we allow null value therefore we should use directly dateData
              // instead the above number type value
              value={dateData.approvedLcoLimit}
              items={approvedLcoOptions}
              onChange={(value) => {
                saveDaySettingsMutation.mutate({
                  date: dateData.date,
                  approvedLateCheckouts: value as number,
                  latestAllowedCheckout: dateData.maxLcoTime,
                  propertyId,
                });
              }}
              className={cn('font-bold px-1 rounded', isDefault ? 'bg-gray-200' : 'bg-yellow-300')}
            />
          ) : (
            <span>{dateData.approvedLcoLimit ?? '-'}</span>
          )}
        </>
      );
    },
    [getApprovedLateCheckoutsOptions, saveDaySettingsMutation],
  );

  const renderTableColumn = useCallback(
    (
      dateData: WeeklyViewDateSummary,
      propertyId: string,
      allowDaySettingsEdit: boolean,
      showArrivalAndDeparturePopovers: boolean,
      memberBenefitsEnabled: boolean,
      omitRightBorder?: boolean,
    ) => {
      const rightBorderClass = omitRightBorder ? '' : 'border-r border-gray-100';
      return (
        <div key={`${formatDate(dateData.date)}-${propertyId}`} className={'flex flex-col flex-1 items-center'}>
          {showArrivalAndDeparturePopovers ? (
            <>
              <div className={'w-full'}>
                <Popover
                  onOpen={() =>
                    setUnitsRequest({
                      propertyId,
                      type: 'arrivals',
                      date: dateData.date,
                    })
                  }
                  className={'w-full'}
                  targetClasses={'w-full'}
                  target={renderTableCell(dateData.arrivals, rightBorderClass)}
                  content={renderPopoverContent(arrivalsOnDay)}
                />
              </div>
              {memberBenefitsEnabled && (
                <div className={'w-full '}>
                  <Popover
                    onOpen={() =>
                      setUnitsRequest({
                        propertyId,
                        type: 'arrivals',
                        date: dateData.date,
                      })
                    }
                    className={'w-full'}
                    targetClasses={'w-full'}
                    target={renderTableCell(dateData.memberArrivals, rightBorderClass)}
                    content={renderPopoverContent(memberArrivalsOnDay)}
                  />
                </div>
              )}
              <div className={'w-full'}>
                <Popover
                  onOpen={() =>
                    setUnitsRequest({
                      propertyId,
                      type: 'departures',
                      date: dateData.date,
                    })
                  }
                  className={'w-full'}
                  targetClasses={'w-full'}
                  target={renderTableCell(dateData.departures, rightBorderClass)}
                  content={renderPopoverContent(departuresOnDay)}
                />
              </div>
              <div className={'w-full'}>
                <Popover
                  onOpen={() =>
                    setUnitsRequest({
                      propertyId,
                      type: 'midstays',
                      date: dateData.date,
                    })
                  }
                  className={'w-full'}
                  targetClasses={'w-full'}
                  target={renderTableCell(dateData.midstays, rightBorderClass)}
                  content={renderPopoverContent(midstaysOnDay)}
                />
              </div>
              <div className={'w-full'}>
                <Popover
                  onOpen={() =>
                    setUnitsRequest({
                      propertyId,
                      type: 'hskDelays',
                      date: dateData.date,
                    })
                  }
                  className={'w-full'}
                  targetClasses={'w-full'}
                  target={renderTableCell(dateData.hskDelays, rightBorderClass)}
                  content={renderPopoverContent(hskDelaysOnDay)}
                />
              </div>
            </>
          ) : (
            <>
              {renderTableCell(dateData.arrivals, rightBorderClass)}
              {memberBenefitsEnabled && renderTableCell(dateData.memberArrivals, rightBorderClass)}
              {renderTableCell(dateData.departures, rightBorderClass)}
              {renderTableCell(dateData.midstays, rightBorderClass)}
              {renderTableCell(dateData.hskDelays, rightBorderClass)}
            </>
          )}
          {renderTableCell(dateData.vacant, rightBorderClass)}
          {renderTableCell(`${dateData.occupancy}%`, rightBorderClass)}
          <div className={'py-2 w-full border-b border-gray-100'}>
            <div
              className={`flex flex-row items-center justify-center space-x-0.5 py-1 w-full text-center text-th-secondary font-semibold text-sm md:text-base ${rightBorderClass}`}
            >
              {renderApprovedLco(dateData, propertyId, allowDaySettingsEdit)}
            </div>
          </div>
          <div className={'py-2 w-full'}>
            <div
              className={`flex flex-row items-center justify-center space-x-0.5 py-1 w-full text-center text-th-secondary font-semibold text-sm md:text-base ${rightBorderClass}`}
            >
              {renderMaxLcoTime(dateData, propertyId, allowDaySettingsEdit)}
            </div>
          </div>
        </div>
      );
    },
    [
      unitsRequest,
      arrivalsOnDay,
      departuresOnDay,
      midstaysOnDay,
      hskDelaysOnDay,
      memberArrivalsOnDay,
      renderApprovedLco,
      renderMaxLcoTime,
    ],
  );

  const renderTable = useCallback(
    (propertyData: WeeklyViewPropertyData, propertyId: string, memberBenefitsEnabled: boolean) => {
      const isOperations = isOperationsRole(propertyId);
      const isMaintainer = isMaintainerRole(propertyId);
      const isRoomchecker = isRoomcheckerRole(propertyId);
      return (
        <div className={'flex flex-col flex-1 rounded-lg'}>
          {renderTableHeader(propertyData.name)}
          <div className={'flex flex-row bg-white px-2'}>
            {propertyData.data.map((d, i) =>
              renderTableColumn(
                d,
                propertyId,
                isOperations && !isBefore(toDate(d.date), today),
                isMaintainer || isOperations || isRoomchecker,
                memberBenefitsEnabled,
                i === propertyData.data.length - 1,
              ),
            )}
          </div>
        </div>
      );
    },
    [isOperationsRole, isMaintainerRole, isRoomcheckerRole, renderTableColumn, renderTableHeader],
  );

  const countTotal = useCallback(
    (propertyData: WeeklyViewPropertyData, subject: 'arrivals' | 'departures' | 'midstays' | 'memberArrivals') => {
      return propertyData.data.reduce((acc, curr) => acc + curr[subject], 0);
    },
    [],
  );

  const renderSection = useCallback(
    (propertyData: WeeklyViewPropertyData, propertyId: string) => {
      const selectedPropertyData = selectedProperties.find((p) => p.id === propertyId);
      const memberBenefitsEnabled = isMemberBenefitsActivated(selectedPropertyData?.memberBenefits || []);

      return (
        <div key={propertyId} className={'flex flex-row mb-6'}>
          <div className={'flex flex-col w-35 flex-shrink-0'}>
            <div className={'py-2 md:py-2.5 text-th-primary uppercase serif font-bold inline leading-none truncate'}>
              {propertyData.name}
            </div>
            <div className={'py-3 border-b border-transparent text-th-brown text-sm truncate text-sm md:leading-6'}>
              {t('arrivals')} ({countTotal(propertyData, 'arrivals')})
            </div>
            {memberBenefitsEnabled && (
              <div
                className={
                  'py-3 border-b border-transparent text-th-brown text-sm truncate text-sm md:leading-6 flex mr-1'
                }
              >
                <icons.member className={'w-3 h-5 mr-1'}></icons.member>
                {t('memberArrivals')} ({countTotal(propertyData, 'memberArrivals')})
              </div>
            )}
            <div className={'py-3 border-b border-transparent text-th-brown text-sm truncate text-sm md:leading-6'}>
              {t('departures')} ({countTotal(propertyData, 'departures')})
            </div>
            <div className={'py-3 border-b border-transparent text-th-brown text-sm truncate text-sm md:leading-6'}>
              {t('midstayCleaning')} ({countTotal(propertyData, 'midstays')})
            </div>
            <div className={'py-3 border-b border-transparent text-th-brown text-sm truncate text-sm md:leading-6'}>
              {t('hskDelays')}
            </div>
            <div className={'py-3 border-b border-transparent text-th-brown text-sm truncate text-sm md:leading-6'}>
              {t('roomsToSell')}
            </div>
            <div className={'py-3 border-b border-transparent text-th-brown text-sm truncate text-sm md:leading-6'}>
              {t('occupancy')}
            </div>
            <div className={'py-3 border-b border-transparent text-th-brown text-sm truncate text-sm md:leading-6'}>
              {t('approvedLCOs')}
            </div>
            <div className={'py-3 border-b border-transparent text-th-brown text-sm truncate text-sm md:leading-6'}>
              {t('lcoUntil')}
            </div>
          </div>
          <div className={'flex flex-1'}>{renderTable(propertyData, propertyId, memberBenefitsEnabled)}</div>
        </div>
      );
    },
    [renderTable],
  );

  if (isIdle || !data) {
    return <LoadingMessage />;
  }

  if (error) {
    return <div>{t('anErrorHasOccurred')}</div>;
  }

  return (
    <div className={'flex flex-col'}>
      {Object.keys(data).map((propId) => {
        return renderSection(data[propId], propId);
      })}
    </div>
  );
}
