import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UserGlobalRole, UserRole } from '@typings/types';
import { Team } from '@typings/enums';

const ROLE_CLAIMS = 'https://hsk-app.com/roles';
export const MASTER_GX_ROLE = 'apaleoExtension-admin';
const HSK_ROLE_PREFIX = 'hsk:';
const PROPERTY_AND_ROLE_SEPARATOR = ':';

interface RolesAndPropertyRoles {
  globalRoles: (UserGlobalRole | UserRole.GX)[];
  propertyRoles: {
    [key: string]: UserRole[];
  };
}

const emptyRoles: RolesAndPropertyRoles = { globalRoles: [], propertyRoles: {} };

export default function useRoleBasedUI() {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const [roles, setRoles] = useState<RolesAndPropertyRoles>(emptyRoles);

  const extractRoles = useCallback(async () => {
    const claims = await getIdTokenClaims();
    const roleClaims: string[] = claims ? claims[ROLE_CLAIMS] : [];

    const appRoles: string[] = roleClaims
      .filter((r: string) => r.startsWith(HSK_ROLE_PREFIX))
      .map((r: string) => r.substring(HSK_ROLE_PREFIX.length));

    const result = appRoles.reduce(
      (acc, curr) => {
        const parts = curr.split(PROPERTY_AND_ROLE_SEPARATOR);
        if (parts.length === 1) {
          acc.globalRoles = [...acc.globalRoles, parts[0] as UserGlobalRole];
        } else {
          const [property, role] = parts;
          if (!acc.propertyRoles[property]) {
            acc.propertyRoles[property] = [];
          }
          acc.propertyRoles[property].push(role as UserRole);
        }
        return acc;
      },
      // emptyRoles cannot be used since this will change it
      { globalRoles: [], propertyRoles: {} } as RolesAndPropertyRoles,
    );

    const gxRole = roleClaims.find((role) => role === MASTER_GX_ROLE);
    if (gxRole) {
      result.globalRoles.push(UserRole.GX);
    }
    setRoles(result);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      extractRoles();
    } else {
      setRoles(emptyRoles);
    }
  }, [isAuthenticated, getIdTokenClaims]);

  /**
   * Returns roles for given property. It includes operations and gx role from global roles
   */
  const getPropertyRoles = useCallback(
    (propertyId?: string) => {
      const res = propertyId && roles.propertyRoles[propertyId] ? [...roles.propertyRoles[propertyId]] : [];
      if (roles.globalRoles.includes(UserRole.GX)) {
        res.push(UserRole.GX);
      }
      if (roles.globalRoles.includes(UserGlobalRole.OPERATIONS_ADMIN)) {
        res.push(UserRole.OPERATIONS);
      }
      return res;
    },
    [roles],
  );

  /**
   * Returns roles for given property, including operations, but excluding gx role from global roles
   * It is used in conditions when we are checking for existence of one role only
   */
  const getPropertyRolesExcludingGx = useCallback(
    (propertyId: string) => {
      return getPropertyRoles(propertyId).filter((r) => r !== UserRole.GX);
    },
    [getPropertyRoles],
  );

  const isOperationsRole = useCallback(
    (propertyId: string) => {
      const propertyRoles = getPropertyRoles(propertyId);
      return propertyRoles.includes(UserRole.OPERATIONS);
    },
    [getPropertyRoles],
  );

  const isMaintainerRole = useCallback(
    (propertyId: string) => {
      const propertyRoles = getPropertyRoles(propertyId);
      return propertyRoles.includes(UserRole.MAINTAINER);
    },
    [getPropertyRoles],
  );

  const isRoomcheckerRole = useCallback(
    (propertyId: string) => {
      const propertyRoles = getPropertyRoles(propertyId);
      return propertyRoles.includes(UserRole.ROOMCHECKER);
    },
    [getPropertyRoles],
  );

  const isCleanerRole = useCallback(
    (propertyId: string) => {
      const propertyRoles = getPropertyRoles(propertyId);
      return propertyRoles.includes(UserRole.CLEANER);
    },
    [getPropertyRoles],
  );

  const isCleanerOnlyRole = useCallback(
    (propertyId: string) => {
      const propertyRoles = getPropertyRolesExcludingGx(propertyId);
      return propertyRoles.length === 1 && propertyRoles.includes(UserRole.CLEANER);
    },
    [getPropertyRolesExcludingGx],
  );

  const isMaintainerOnlyRole = useCallback(
    (propertyId: string) => {
      const propertyRoles = getPropertyRolesExcludingGx(propertyId);
      return propertyRoles.length === 1 && propertyRoles.includes(UserRole.MAINTAINER);
    },
    [getPropertyRolesExcludingGx],
  );

  const isGxOnlyRole = useCallback(
    (propertyId: string) => {
      const propertyRoles = getPropertyRoles(propertyId);
      const nonGxRoles = propertyRoles.filter((r) => r !== UserRole.GX).length;
      return propertyRoles.length > 0 && nonGxRoles === 0;
    },
    [getPropertyRoles],
  );

  const getTeamFromUserRole = useCallback(
    (propertyId: string) => {
      const propertyRoles = getPropertyRoles(propertyId);
      if (propertyRoles.includes(UserRole.ROOMCHECKER)) {
        return Team.HOUSE_KEEPING;
      }
      if (propertyRoles.includes(UserRole.CLEANER)) {
        return Team.HOUSE_KEEPING;
      }
      if (propertyRoles.includes(UserRole.MAINTAINER)) {
        return Team.MAINTENANCE;
      }
      if (propertyRoles.includes(UserRole.OPERATIONS)) {
        return Team.OPERATIONS;
      }
    },
    [getPropertyRoles],
  );

  /**
   * whether user has roomchecker or operations role in at least one property
   */
  const rolesWithAssignRoomsPrivileges = useMemo(
    () =>
      roles.globalRoles.includes(UserGlobalRole.OPERATIONS_ADMIN) ||
      Object.keys(roles.propertyRoles).some((propertyId) => {
        return roles.propertyRoles[propertyId].some((r) => [UserRole.ROOMCHECKER, UserRole.OPERATIONS].includes(r));
      }),
    [roles],
  );

  return {
    getTeamFromUserRole,
    getPropertyRoles,
    isOperationsRole,
    isMaintainerRole,
    isRoomcheckerRole,
    isCleanerRole,
    isCleanerOnlyRole,
    isMaintainerOnlyRole,
    isGxOnlyRole,
    rolesWithAssignRoomsPrivileges,
  };
}
