import { useHistory } from 'react-router-dom';

const routes = {
  ROOT: '/',
  SIGN_IN: '/sign-in',
  LOST_AND_FOUND: '/lost-and-found',
  DAMAGE_REPORT: '/damage-report',
  ASSIGN_ROOMS: '/assign',
  UNIT_DETAILS: '/units/:id',
  TASKS: '/tasks',
  TASK_DETAILS: '/tasks/:id',
  SETTINGS: '/settings',
  WEEKLY: '/weekly',
  NOTIFICATIONS: '/notifications',
  APALEO_HOUSEKEEPING: '/apaleo/hsk',
};

type History = ReturnType<typeof useHistory>;

export const goToUnitDetails =
  (history: History) =>
  ({
    unitId,
    propertyId,
    expandTasks,
    expandDamageReports,
    openCreationModal,
  }: {
    unitId: string;
    propertyId: string;
    expandTasks?: boolean;
    expandDamageReports?: boolean;
    openCreationModal?: boolean;
  }) => {
    history.push({
      pathname: routes.UNIT_DETAILS.replace(':id', unitId),
      search: `propertyId=${propertyId}`,
      state: { expandTasks, expandDamageReports, openCreationModal },
    });
  };

export const goToTaskDetails = (history: History) => (id: string) =>
  history.push({ pathname: routes.TASK_DETAILS.replace(':id', id) });

export default routes;
