import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  StandardizedTask,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedTasks = {
  [StandardizedTask.TRASH]: 'Wynieść śmieci',
  [StandardizedTask.SMOKE]: 'Pokój pachnie jak dym',
  [StandardizedTask.SOFA_1]: 'Przygotować sofę na 1 pax',
  [StandardizedTask.SOFA_2]: 'Przygotować sofę na 2 pax',
  [StandardizedTask.BABY_BED]: 'Przynieść łóżeczko dla dziecka',
  [StandardizedTask.DRYING_RACK]: 'Przynieść suszarkę do ubrań',
  [StandardizedTask.TOWELS]: 'Przynieść ręczniki',
  [StandardizedTask.IRON_AND_BOARD]: 'Przynieść żelazko i deskę',
  [StandardizedTask.TOILET_PAPER]: 'Przynieść papier toaletowy',
  [StandardizedTask.NO_SHOW_AFTER_10]: 'Sprawdzić no-show od 10 rano',
  [StandardizedTask.PARTY_ROOM_DAMAGE]: 'Pokój imprezowy - sprawdzić uszkodzenia',
  [StandardizedTask.LCO_11_30]: 'LCO - późne wymeldowanie o 11:30',
  [StandardizedTask.LCO_12]: 'LCO - późne wymeldowanie o 12:00',
  [StandardizedTask.QUARANTINE]: 'Kwarantanna - HSK nie wchodzić',
  [StandardizedTask.COFFEE_PODS]: 'Przynieść dodatkowe kapsułki z kawą',
  [StandardizedTask.KITCHEN_TOWEL]: 'Przynieść ręcznik kuchenny',
  [StandardizedTask.BLANKET]: 'Przynieść koc',
  [StandardizedTask.CHECK_FOR_MAIL]: 'Check for mail',
  [StandardizedTask.BABY_CHAIR]: 'Przynieść krzesełko dziecięce',
  [StandardizedTask.TURN_HEATING_ON]: 'Turn heating on before CI',
  [StandardizedTask.SORRY_TREATMENT]: 'Przynieść przeprosinowy podarunek',
  [StandardizedTask.REFILL_HSK_HUB]: 'Uzupełnić HSK Hub',
  [StandardizedTask.BRING_FAN_TO_ROOM]: 'Przynieść wentylator do pokoju',
  [StandardizedTask.DO_NOT_DISTURB_GUEST]: 'Nie przeszkadzać gościowi',
  [StandardizedTask.PRIORITIZE_ROOM_CLEANING]: 'Priorytetyzacja sprzątania pokoju',
  [StandardizedTask.SHAMPOO_REFILL]: 'Uzupełnić szampon',
  [StandardizedTask.SHOWER_GEL_REFILL]: 'Uzupełnić żel pod prysznic',
  [StandardizedTask.BODY_LOTION_REFILL]: 'Uzupełnić balsam do ciała',
  [StandardizedTask.BRING_KITCHEN_TOWEL]: 'Przynieść ręcznik kuchenny',
  [StandardizedTask.BRING_FRYING_PAN]: 'Przynieść patelnię',
  [StandardizedTask.BRING_COOKING_POT]: 'Przynieść garnek do gotowania',
  [StandardizedTask.BRING_WATER_GLASS]: 'Przynieść szklanki',
  [StandardizedTask.BRING_WINE_GLASS]: 'Przynieść kieliszki do wina',
  [StandardizedTask.BRING_CUP]: 'Przynieść kubek',
  [StandardizedTask.BRING_PLATES]: 'Przynieść płyty',
  [StandardizedTask.BRING_BOWL]: 'Przynieść miskę',
  [StandardizedTask.BRING_PAPER_TOWELS]: 'Przynieść ręczniki papierowe',
  [StandardizedTask.CLEAN_ELEVATOR]: 'Posprzątać windę',
  [StandardizedTask.CLEAN_ENTRANCE]: 'Posprzątać wejście',
  [StandardizedTask.CLEAN_HALLWAYS]: 'Posprzątać korytarze',
  [StandardizedTask.CLEAN_LAUNDRY_ROOM]: 'Posprzątać pralnię',
  [StandardizedTask.CLEAN_LUGGAGE_LOCKERS]: 'Posprzątać szafki bagażowe',
  [StandardizedTask.CLEAN_STAIRS]: 'Posprzątać schody',
  [StandardizedTask.CLEAN_TOILET]: 'Posprzątać toaletę',
  [StandardizedTask.CLEAN_PUBLIC_TOILETS]: 'Posprzątać publiczne toalety',
  [StandardizedTask.CLEAN_TERRACE]: 'Posprzątać taras',
  [StandardizedTask.CLEAN_BALCLONY]: 'Posprzątać balkon',
  [StandardizedTask.CLEAN_WASHING_MACHINE_DRYER]: 'Posprzątać pralki i suszarki (pralnia)',
  [StandardizedTask.DOUBLE_CHECK_ROOM]: 'Ponownie sprawdzić pokój',
  [StandardizedTask.CHECK_LAUNDRY_DELIVERY]: 'Sprawdzić dostawę prania',
  [StandardizedTask.CHECK_CONSUMABLES_EXPIRATION]: 'Sprawdzić datę ważności materiałów eksploatacyjnych',
  [StandardizedTask.RESET_LUGGAGE_LOCKERS]: 'Sprawdzić (reset) szafki bagażowe',
  [StandardizedTask.CHECK_MAILBOX]: 'Sprawdzić skrzynkę pocztową',
  [StandardizedTask.REFILL_ESSENTIAL_CLOSET]: 'Uzupełnić szafę z niezbędnymi rzeczami',
  [StandardizedTask.REFILL_DETERGENT]: 'Uzupełnić detergent',
  [StandardizedTask.ORGANIZE_STORAGE]: 'Poukładać schowek',
  [StandardizedTask.ORDER_CRISPS]: 'Zamówić chipsy',
  [StandardizedTask.ORDER_SOFT_DRINK]: 'Zamówić sok/napój bezalkoholowy',
  [StandardizedTask.ORDER_BEER]: 'Zamówić piwo',
  [StandardizedTask.ORDER_WATER]: 'Zamówić wodę',
  [StandardizedTask.ORDER_EARPLUGS]: 'Zamówić zatyczki do uszu',
  [StandardizedTask.ORDER_SWEETS]: 'Zamówić słodycze / Candy Kittens',
  [StandardizedTask.ORDER_COFFEE]: 'Zamówić kawę / Grind',
  [StandardizedTask.ORDER_TEA]: 'Zamówić herbatę / Hoogly',
  [StandardizedTask.TAKE_OUT_DIRTY_LAUNDRY]: 'Wyjąć brudne pranie',
  [StandardizedTask.WATER_FLOWERS]: 'Podlać kwiaty',
  [StandardizedTask.WATER_GARDEN]: 'Podlać ogród',
};

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Głośny wentylator w łazience',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'Światło nie działa',
  [StandardizedDamageReport.SINK_BLOCKED]: 'Zlew jest zablokowany',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: 'Odpływ prysznica jest zablokowany',
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Filtr łazienkowy',
  [StandardizedDamageReport.SINK]: 'Zlew',
  [StandardizedDamageReport.TOILET]: 'Toaleta',
  [StandardizedDamageReport.SHOWER]: 'Prysznic',
  [StandardizedDamageReport.STOVE]: 'Kuchenka',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Lodówka/minibar',
  [StandardizedDamageReport.DOOR]: 'Drzwi',
  [StandardizedDamageReport.WINDOW]: 'Okno',
  [StandardizedDamageReport.AC]: 'Klimatyzacja',
  [StandardizedDamageReport.WIFI]: 'Wifi',
  [StandardizedDamageReport.LIGHTBULB]: 'Żarówka',
  [StandardizedDamageReport.CHAIRS]: 'Krzesła',
  [StandardizedDamageReport.HEATING]: 'Ogrzewanie',
  [StandardizedDamageReport.TV]: 'Telewizor',
  [StandardizedDamageReport.TABLES]: 'Tabele',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: 'Zespół HSK',
  [Team.MAINTENANCE]: 'Zespół serwisowy',
  [Team.OPERATIONS]: 'Zespół operacyjny',
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: 'Kuchnia',
  [DamageReportArea.BED]: 'Łóżko',
  [DamageReportArea.BATHROOM]: 'Łazienka',
  [DamageReportArea.SEATING_AREA]: 'Strefa siedząca',
  [DamageReportArea.WALLS_CEILING_FLOOR]: 'Ściany/sufit/podłoga',
  [DamageReportArea.ICT]: 'ICT',
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: 'Posprzątać',
  [DamageReportActionRequired.REPLACE]: 'Zamienić',
  [DamageReportActionRequired.REPAIR]: 'Naprawić',
  [DamageReportActionRequired.CHECK]: 'Sprawdzić',
  [DamageReportActionRequired.OTHER]: 'Inny',
};

const taskPriorities = {
  [TaskPriority.LOW]: 'Niski',
  [TaskPriority.HIGH]: 'Wysoki',
};

const popupNotifications = {
  taskDeletionNotification: 'Zadanie usunięte',
  lostAndFoundDeletionNotification: 'Zgubiony i znaleziony przedmiot usunięty',
  damageReportDeletionNotification: 'Raport o uszkodzeniach usunięty',
  taskCreationNotification: 'Zadanie utworzone',
  lostAndFoundCreationNotification: 'Zgubiony i znaleziony przedmiot utworzony',
  damageReportCreationNotification: 'Raport o uszkodzeniach utworzony',
  taskCompletionNotification: 'Zadanie zakończone',
  lostAndFoundCompletionNotification: 'Zgubiony i znaleziony przedmiot zakończony',
  damageReportCompletionNotification: 'Raport o uszkodzeniach zakończony',
  taskUpdateNotification: 'Zadanie zaktualizowane',
  lostAndFoundUpdateNotification: 'Zgubiony i znaleziony przedmiot zaktualizowany',
  damageReportUpdateNotification: 'Raport o uszkodzeniach zaktualizowany',
  pleaseSelectRoomsNotification: 'Wybierz pokoje',
  noShowReportNotification: 'No-show zgłoszony dla pokoju {{number}}',
  checkInReportNotification: 'Zameldowanie zgłoszone dla pokoju {{number}}',
  damageReportWorkingTimeScheduledNotification: 'Raport o uszkodzeniach zaplanowany na {{date}}',
  hskDelayNotification: 'HSK opóźnienie dla pokoju {{number}}. Czas zameldowania ustawiony na {{time}}',
};

const cleaningInterval = {
  next: 'Next cleaning',
  interval: 'Cleaning interval',
  timeRange: 'Time range',
  lastCleaning: 'Last cleaning',
  stayoverCleaning: 'Stayover cleaning',
  [CleaningInterval.WEEKLY_OR_END]: 'Weekly or end of stay',
  [CleaningInterval.BIWEEKLY]: 'Co dwa tygodnie',
  [CleaningInterval.DAILY]: 'Codziennie',
  [CleaningInterval.NONE]: 'Nic',
};

export default {
  ...teamNames,
  standardizedTasks,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  moreFilters: 'Więcej filtrów',
  resetAllFilters: 'Zresetuj wszystkie filtry',
  reset: 'Zresetuj',
  cleaned: 'Posprzątany',
  allRoomsCleaned: `Wszystkie pokoje czyste`,
  dirty: 'Brudny',
  readyToClean: 'Gotowy do sprzątania',
  stayoverReadyToClean: 'Gotowy do sprzątania podczas pobytu',
  occupiedCleaningToday: 'Zajęty - sprzątanie dzisiaj',
  readyToInspect: 'Gotowy do sprawdzenia',
  stayoverInspection: 'Kontrola podczas pobytu',
  vacant: 'Pusty',
  occupied: 'Zajęty',
  clean: 'Czysty',
  daily: 'Codziennie',
  weekly: 'Co tydzień',
  biweekly: 'Co dwa tygodnie',
  monthly: 'Co miesiąc',
  none: 'Nic',
  tasks: 'Zadania',
  notifications: 'Powiadomienia',
  more: 'Więcej',
  select: 'Wybierz',
  property: 'Nieruchomość',
  result: 'Wynik',
  results: 'Wyniki',
  properties: 'Nieruchomości',
  rooms: 'Pokoje',
  team: 'Zespół',
  dueDate: 'Termin ostateczny',
  date: 'Data',
  close: 'Zamknij',
  search: 'Szukaj',
  login: 'Zaloguj się',
  loading: 'Ładowanie...',
  anErrorHasOccurred: 'Wystąpił błąd',
  chooseDate: 'Wybierz datę',
  selectDate: 'Wybierz datę',
  selectADate: 'Wybierz datę',
  markAsClean: 'Zaznacz jako czysty',
  markAsReadyToInspect: 'Zaznacz jako gotowy do sprawdzenia',
  viewTasks: 'Wyświetl zadania',
  eci: 'ECI - wczesne zameldowanie',
  lco: 'LCO - późne wymeldowanie',
  snacks: 'SNACKS',
  roomLog: `Log pokoju`,
  taskLog: `Log zadania`,
  seeDetails: 'Patrz szczegóły',
  assignedTasks: 'Przydzielone zadania',
  delete: 'Usuń',
  deleteTask: 'Usuń zadanie',
  deleteTaskConfirmationMessage: 'Czy na pewno chcesz usunąć to zadanie?',
  deleteTaskRepeatsConfirmationMessage:
    'To jest powtarzające się zadanie. Możesz również usunąć tylko to lub powtarzające się przyszłe zadania',
  deleteOnlyThisTask: 'Usuń tylko to zadanie',
  deleteRepeatingTask: 'Usuń powtarzające się zadanie',
  yes: 'Tak',
  no: 'Nie',
  pleaseWaitAFewSeconds: 'Proszę poczekaj kilka sekund',
  deleting: 'Usuwanie',
  lostAndFound: 'Zgubiony i znaleziony',
  current: 'Aktualny',
  archive: 'Archiwizuj',
  edit: 'Edytuj',
  markAsResolved: 'Oznacz jako rozwiązane',
  markAsUnresolved: 'Oznacz jako nierozwiązany',
  add: 'Dodaj',
  area: 'Obszar',
  addTask: 'Dodaj zadanie',
  editTask: 'Edytuj zadanie',
  seeTaskDetails: 'Zobacz zadanie',
  savePicturesAndStartCleaning: 'Zapisz i rozpocznij sprzątanie',
  savePictures: 'Zapisz zdjęcia',
  takePhoto: 'Zrób zdjęcie',
  addArea: 'Dodaj obszar',
  addAreaDescription: 'Dodaj obszar hotelu do tego zadania',
  selectProperty: 'Wybierz nieruchomość',
  selectRooms: 'Wybierz pokoje',
  selectTeam: 'Wybierz zespół',
  selectDueDate: 'Wybierz datę',
  hskTeam: 'Zespół HSK',
  save: 'Zapisz',
  actionIsRequired: `Akcja jest wymagana. Usuń tytuł i wybierz akcję z listy.`,
  noActionSelected: `Brak wybranej akcji`,
  changeSearchAction: `Spróbuj zmienić tekst wyszukiwania`,
  noActionFound: `Brak znalezionych akcji`,
  selectActionHelp: `Nie możesz znaleźć akcji? Skontaktuj się z zespołem Ops, aby ją dodać.`,
  writeToSelectAction: 'Pisz, aby wybrać akcję',
  writeTask: 'Napisz zadanie',
  repetition: 'Powtórzenie',
  noRepetition: 'Brak powtórzenia',
  repetitionTime: 'Czas powtórzeń',
  doesNotRepeat: 'Nie powtarzaj',
  day: 'Dzień',
  week: 'Tydzień',
  month: 'Miesiąc',
  repeatOn: 'Powtórz w',
  repeatsEvery: 'Powtórz co',
  description: 'Opis',
  title: 'Tytuł',
  addTitle: 'Dodaj tytuł',
  titleIsRequired: 'Tytuł jest wymagany',
  titleMaxLength: 'Tytuł powinien mieć mniej niż {{maxLength}} znaków.',
  teamIsRequired: 'Zespół jest wymagany',
  openTasks: 'Otwarte zadania',
  arrival: 'Przyjazd',
  departure: 'Wyjazd',
  checkIn: 'Zameldować się',
  times: 'Czasy',
  guests: 'Goście',
  name: 'Nazwisko',
  additionalInfo: 'Dodatkowe informacje',
  addLostAndFound: 'Dodaj zagubiony i znaleziony',
  addDamageReport: 'Dodaj raport o uszkodzeniach',
  unitIsRequired: 'Jednostka jest wymagana',
  addItem: 'Dodaj przedmiot',
  editItem: 'Edytuj przedmiot',
  startCleaning: 'Zacznij sprzątanie',
  endCleaning: 'Zakończ sprzątanie',
  endCleaningMessage: 'Jest 1 otwarte zadanie. Czy na pewno chcesz zakończyć sprzątanie?',
  endCleaningMessage_plural: 'Istnieją {{count}} otwarte zadania. Czy na pewno chcesz zakończyć sprzątanie?',
  cancelAndViewTasks: 'Anuluj i przeglądaj zadania',
  ignoreAndMarkAsReadyToInspect: 'Ignoruj ​​i zaznacz jako gotowe do sprawdzenia',
  ignoreAndMarkAsCleaned: 'Ignoruj ​​i zaznacz jako posprzątane',
  damageReports: 'Raporty o uszkodzeniach',
  confirm: 'Potwierdź',
  selectRoomOrArea: 'Wybierz pokój/obszar lub',
  createNewOne: 'Utwórz nowy',
  today: 'Dzisiaj',
  tomorrow: 'Jutro',
  noTasksMessage: 'Brak zadań',
  cleaningStatus: 'Sprzątanie',
  logOut: 'Wyloguj',
  language: 'Język',
  selectLanguage: 'Wybierz język',
  isStandardized: 'Jest zdefiniowany?',
  selectStandardizedTask: 'Wybierz zdefiniowane zadanie',
  overdue: 'Zaległy',
  arrivals: 'Przyjazdy',
  memberArrivals: 'Member arrivals',
  departures: 'Wyjazdy',
  stayovers: 'Stayovers',
  occupancy: 'Obłożenie OTB',
  midstayCleaning: 'Sprzątanie podczas pobytu',
  roomsToSell: 'Pokoje na sprzedaż',
  editImages: 'Edytuj obrazy',
  toClean: 'Do posprzątania',
  toInspect: 'Do sprawdzenia',
  noRoomsForCleaningToday: 'Brak pokoi do posprzątania dzisiaj',
  noShow: 'Brak zameldowania',
  noShowReportConfirmationMessage: 'Potwierdź, że numer pokoju {{number}} nie jest show.',
  noShowReportQuestion: 'Czy gość zameldował się wczoraj?',
  checkInReportConfirmationMessage: 'Potwierdź, że gość zameldował się w pokoju {{number}}.',
  noResultsSearchMessage: 'Brak wyników dla kryteriów wyszukiwania',
  cancel: 'Anuluj',
  selectCleaner: 'Wybierz sprzątacza',
  selectAll: 'Zaznacz wszystko',
  floor: 'Podłoga',
  notAssigned: 'Nie przypisano',
  assignXRooms: 'Przypisz pokoje {{total}}',
  assignRooms: 'Przypisz pokoje',
  approvedLCOs: 'Zatwierdzone późne wymeldowanie',
  cleaner: 'Sprzątacz',
  roomsCleanForToday: 'Wszystkie pokoje są na dziś czyste! 🎉🎉',
  viewDamageReports: 'Zobacz raporty o szkodach',
  noTasks: 'Brak zadań',
  noDamageReports: 'Brak raportów o szkodach',
  dailyCleaningReport: 'Raport codziennego czyszczenia',
  stayover: 'Kontynuacja pobytu',
  roomMoveFrom: 'Przeniesienie z pokoju {{value}}',
  roomMoveTo: 'Przeniesienie do pokoju {{value}}',
  unassignAll: 'Usuń {{total}} przypisania',
  unassignRoomsConfirmationMessage: 'Czy na pewno usunąć przypisanie z {{total}} pokoi?',
  unassignRoomsTitle: 'Usuń przypisanie pokoje',
  noRoomAssigned: 'Brak przypisanego pokoju',
  action: 'Akcja',
  hideActions: 'Ukryj akcje',
  showActions: 'Pokaż akcje',
  actionsSelected: '{{total}} wybrane(a) akcje(a)',
  disableActionTitleModal: 'Zresetuj filtr {{name}}',
  disableActionDescriptionModal: 'Aby użyć filtra {{currentFilter}}, zresetuj filtr {{resetFilter}}.',
  actionRequired: 'Wymagane działanie',
  priority: 'Priorytet',
  lcoUntil: 'Późne wymeldowanie do',
  created: 'Utworzony',
  startNow: 'Zacznij teraz',
  scheduleTime: 'Zaplanuj czas',
  viewInfo: 'Zobacz informacje',
  reminder: 'Przypomnienie',
  reminderTaskNotificationText: 'Twoje zaplanowane zadanie kończy się dzisiaj',
  inProgress: 'W trakcie',
  scheduled: 'Zaplanowany',
  moveMidstayCleaning: 'Czy przesunąć sprzątanie podczas pobytu?',
  moveMidstayTitle: 'Przesuń sprzątanie podczas pobytu',
  moveMidstayDescription: 'Czy na pewno chcesz przesunąć sprzątanie podczas pobytu na {{date}}?',
  moveMidstayNotPossible: 'Przepraszamy! Sprzątanie podczas pobytu nie można przenieść na kolejny dzień!',
  moveMidstayNotification: 'Sprzątanie podczas pobytu przesunięto na {{date}}.',
  pleaseContactOps: 'Skontaktuj się z zespołem operacyjnym',
  midCleanMovedTo: 'Sprzątanie podczas pobytu przesunięto na {{value}}.',
  optional: 'opcjonalny',
  hskDelay: 'Opóźnienie HSK',
  hskDelays: 'Opóźnienia HSK',
  hskDelayMessage: 'Aktualizacja czasu zameldowania dla pokoju {{number}}',
  notStarted: 'Nie rozpoczęty',
  resolved: 'Rozwiązany',
  noDamageReportsToday: 'Brak raportów dotyczących szkód na dziś',
  yourTask: 'Twoje zadanie',
  yourTaskGX: 'Twoje zadanie od GX',
  damageReportedByGX: 'Uszkodzenie zgłoszone przez GX',
  stay: 'Daty pobytu',
  checkOut: 'Godzina wymeldowania',
  noPermission: 'Nie masz uprawnień, proszę skontaktować się z pomocą techniczną.',
  approvedCheckoutTime: 'Zatwierdzony czas wymeldowania',
  requestedCheckoutTime: 'Żądany czas wymeldowania',
  checkinTime: 'Czas check-inu',
  commentForHousekeeping: 'Komentarz dla personelu sprzątającego',
  numberOfApprovedLCOs: 'Liczba zatwierdzonych LCO',
  notSet: 'Nie ustawiono',
  vip: 'VIP',
  extraService: 'Extra service',
  reservationMissing: 'Rezerwacja najprawdopodobniej została anulowana, przejdź zamiast tego do zakładki Ogólne.',
  confirmFor: 'Potwierdź na {due date}',
  confirmAnotherDay: 'Potwierdź inny dzień',
  taskConfirmed: 'Potwierdziłeś zadanie na {due date}.',
  confirmYourTask: 'Potwierdź swoje zadanie',
  confirmYourTaskGX: 'Potwierdź swoje zadanie z GX.',
  confirmHighPriorityTaskMessage:
    'Czy naprawdę chcesz ustawić dzisiejszy termin wykonania? Prawdopodobnie to zadanie nie zostanie dzisiaj potwierdzone i ukończone.',
  setTomorrow: 'Ustaw jutro',
  keepToday: 'Zachowaj dzisiaj',
  createTask: 'Utwórz zadanie',
  confirmationNeeded: 'Potrzebne potwierdzenie',
};
